import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Heading = styled.h1`
  display: inline-flex;
  font-family: Teko;
  color: #fff;
  background: #000;
  text-decoration: none;
  padding: 0 20px;
  align-items: center;
  line-height: 1.25;
  font-size: 26px;
  font-weight: normal;
  margin: 0;
`

const Body = styled.div`
  font-size: 18px;
  font-family: Roboto Condensed;
`

const Wrapper = styled.div`
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
`

const NotFoundPage = () => (
  <Layout withHeader>
    <SEO title="404: Not found" />
    <Wrapper>
      <Heading>NOT FOUND</Heading>
      <Body>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Body>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
